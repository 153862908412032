import React from 'react';
import './Tooltip.css';

const Tooltip = ({ children, tooltipText, color }) => {
    return (
        <div className="tooltip">
            {children}
            <div className="tooltipText" style={{color: color, fontWeight: 'bold'}}>{tooltipText}</div>
        </div>
    );
};

export default Tooltip;