import '../../Style.css';
import React, {useContext, useEffect, useState} from "react";
import Gallery from "../Gallery/Gallery";
import CustomSelect from "../../Element/Custom/CustomSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../../Element/Modal/ModalProvider";
import EditCategoryModal from "../../Element/Modal/EditCategoryModal";
import {UserContext} from "../../App";

export default function Home({isObjects, isCategories, categoryId, isLoadingCategories, reloadAfterChanges}) {
    const { openModal } = useModal();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const thresholdWidth = 1025;

    const user = useContext(UserContext);
    const checkIfUserHasPermission = (permission) =>
        user?.group_id === 1 ?
            true :
            (user?.[`perm_${permission}`] === 1 && user.status === "active") || false;

    useEffect(() => {
        const handleResize = () => { setWindowWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openEditModal = (targetObject) => {
        openModal(
            <EditCategoryModal
                dataId={targetObject.id}
                initialData={targetObject}
                secondData={isCategories}
                reloadAfterChanges={reloadAfterChanges}
            />
        );
    };

    const getCategoryNameForTitle = () => {
        if (Array.isArray(isCategories)) {
            const categoryIdNumber = parseInt(categoryId, 10);
            const targetObject = isCategories.find(obj => obj.id === categoryIdNumber);
            const categoryNameSelected = targetObject && targetObject.name ? targetObject.name : 'Übersicht der neuesten 30 Bilder';
            const categoryDescriptionSelected = targetObject && targetObject.description ? targetObject.description : '';

            return (
                <div className="innerContentHeader">
                    <div className="innerContentTitleWrapper">
                        <h2>
                            {categoryNameSelected}
                        </h2>
                        <h3 style={{color: 'white', marginTop: '0.15rem'}}>
                            {categoryDescriptionSelected}
                        </h3>
                    </div>

                    {checkIfUserHasPermission('category') && targetObject && (
                        <div className="innerContentHeaderAdminButtons">
                            <button
                                style={{borderRadius: '0.5rem', fontSize: '1rem', padding: '0'}}
                                className="appButtonEmpty"
                                onClick={() => {
                                    openEditModal(targetObject);
                                }}
                            >
                                {<FontAwesomeIcon icon={faGear}/>}
                            </button>
                        </div>
                    )}

                </div>
            );
        }
    };

    const additionalHomeObject = { id: 0, name: "Startseite", description: "Zurück zur Startseite", creation_date: "0" };

    return (
        <>
            {windowWidth < thresholdWidth && (
                <div className="contentNavigationWrapper">
                    <h3 className="contentNavigationTitle">
                        {`Navigation`}
                    </h3>
                    {!isLoadingCategories && (
                        <CustomSelect
                            centered={true}
                            optionArray={[additionalHomeObject, ...isCategories]}
                            defaultValue={categoryId && additionalHomeObject.name}
                            useLink={true}
                            linkPath="/category"
                            sortKey="name"
                            order="asc"
                        />
                    )}
                </div>
            )}

            {isCategories && getCategoryNameForTitle()}

            {isObjects && isObjects.length > 0 ? (
                <Gallery images={isObjects} categories={isCategories} reloadAfterChanges={reloadAfterChanges} isResponsive={windowWidth < thresholdWidth} />
            ) : (
                <div className="messageWrapper">
                    <div className="infoMessage">Es sind noch keine Bilder in dieser Kategorie</div>
                </div>
            )}
        </>
    )
}
