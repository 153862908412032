import React, { useEffect, useRef, useState } from "react";
import TimestampConverter from "../../Element/Utilities/TimestampConverter";
import DataSorter from "../../Element/Utilities/DataSorter";
import axios from "axios";
import { useLocalStorage } from "usehooks-ts";
import { useCookies } from "react-cookie";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipImage from "../../Element/Tooltip/TooltipImage";

export default function PreviewComments({ currentImageData, currentUser, reloadAfterChanges }) {
    const [comments, setComments] = useState([]);
    const [isUser] = useLocalStorage("isUser", "");
    const [isToken] = useLocalStorage("isToken", "");
    const [isMessage, setMessage] = useState("");
    const [isResponse, setResponse] = useState({});
    const [remainingTime, setRemainingTime] = useState(null);
    const isUserAuthorized = currentUser.message === "authorisiert" && currentUser.status === "active";
    const deleteButtonTimerRef = useRef(null);
    const [isDeleteButtonText, setDeleteButtonText] = useState("");
    const [isSelectedCommentToDelete, setSelectedCommentToDelete] = useState(null);

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    // GET Comments for imageID
    useEffect(() => {
        axios
            .get(`/userComments.php/${currentImageData.id}`)
            .then((response) => {
                setComments(response.data);
            })
            .catch((error) => {
                console.error("Fehler beim Laden der Kommentare: ", error);
            });
    }, [currentImageData.id, isResponse]); // isResponse wurde hinzugefügt

    // Extract users last comment entry dep on creation_date
    const resultObject = comments.reduce((maxObject, currentObject) => {
        if (currentObject.user_id === currentUser.user_id) {
            // Vergleiche creation_date und aktualisiere maxObject, wenn das aktuelle Objekt ein höheres creation_date hat
            if (!maxObject || currentObject.creation_date > maxObject.creation_date) {
                return currentObject;
            }
        }
        return maxObject;
    }, null);

    useEffect(() => {
        if (resultObject) {
            const timerId = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const timeDifference = currentTime - resultObject.creation_date;
                const timeRemaining = Math.max(0, 300 - timeDifference); // 300 Sekunden = 5 Minuten
                setRemainingTime(timeRemaining);
            }, 1000);

            return () => clearInterval(timerId);
        } else {
            // Wenn resultObject auf null gesetzt wird, setze den Timer zurück
            setRemainingTime(0);
        }
    }, [resultObject, currentImageData.id]);

    useEffect(() => {
        // Reset remainingTime only if a comment exists
        if (resultObject) {
            setRemainingTime(0);
        }
    }, [currentImageData.id]);

    async function postComment() {
        try {
            const response = await axios.post(
                `/registeredComments.php`,
                {
                    user_id: currentUser.user_id,
                    message: isMessage,
                    object_id: currentImageData.id,
                    thumbnail_path: currentImageData.thumbnail_path,
                },
                config
            );

            setResponse(response.data);

            // Nach erfolgreichem Kommentar-Post die Kommentare neu abrufen
            axios
                .get(`/userComments.php/${currentImageData.id}`)
                .then((response) => {
                    setComments(response.data);
                    setMessage("");
                    reloadAfterChanges();
                })
                .catch((error) => {
                    console.error("Fehler beim Laden der Kommentare: ", error);
                });
        } catch (error) {
            console.error("Fehler beim Posten des Kommentars: ", error);
        }
    }

    async function deleteComment(commentData) {
        try {
            let response = {};

            if (currentUser.perm_comment === 1 || currentUser.groupId === 1) {
                response = await axios.delete("/mComments.php", {
                    data: { id: commentData.id },
                    headers: config.headers,
                });
            } else {
                response = await axios.delete("/registeredComments.php", {
                    data: { id: commentData.id },
                    headers: config.headers,
                });
            }

            setResponse(response.data);

            // Nach erfolgreichem Kommentar-Delete die Kommentare neu abrufen
            axios
                .get(`/userComments.php/${currentImageData.id}`)
                .then((response) => {
                    setComments(response.data);
                    setMessage("");
                    reloadAfterChanges();
                })
                .catch((error) => {
                    console.error("Fehler beim Laden der Kommentare: ", error);
                });
        } catch (error) {
            console.error("Fehler beim Löschen des Kommentars: ", error);
        }
    }

    const sortedData =
        comments.length > 0 &&
        DataSorter({ dataArray: comments, sortKey: "creation_date", order: "desc" });

    const handleDeleteButtonClick = (comment) => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        if (isSelectedCommentToDelete === comment) {
            deleteComment(comment);
        } else {
            setDeleteButtonText("2x");
            setSelectedCommentToDelete(comment);
        }

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("");
            setSelectedCommentToDelete(null); // Bild zurücksetzen
        }, 2000);
    };

    const handleImageError = (comment) => {
        console.log("Fehler beim Laden des Bildes:", comment.avatar_path);

        // Setzen Sie den Wert des avatar_path des Kommentars auf 0
        const updatedComment = { ...comment, avatar_path: "0" };
        // Aktualisieren Sie den Kommentar in Ihrem State
        const updatedComments = comments.map((c) => (c.id === comment.id ? updatedComment : c));
        setComments(updatedComments);
    };

    return (
        <>
            <div className="previewBottomContentWrapper">
                <h2 className="previewBottomTitle">Kommentare</h2>

                <div className="previewCommentsEntryWrapper">
                    {comments.length > 0 ? (
                        sortedData.map((comment) => (
                            <div key={comment.id} className="userCommentEntry">
                                <div className={`userCommentHeader`}>

                                    <div
                                        className={`userCommentAuthor ${
                                            comment.user_id === currentUser.user_id ? "is-author" : ""
                                        }`}
                                    >
                                        {(comment.avatar_path === "0" || comment.avatar_path === null) ? (
                                            <div className="userCommentIcon">
                                                {comment.user_name.substring(0, 2).toUpperCase()}
                                            </div>
                                        ) : (
                                            <>
                                                <img
                                                    className="userCommentIcon"
                                                    src={comment.avatar_path}
                                                    alt={'1.png'}
                                                    onError={() => handleImageError(comment)}
                                                />
                                            </>
                                        )}
                                        <div className="userInfo">
                                            <div className="userName">{comment.user_name.charAt(0).toUpperCase() + comment.user_name.slice(1)}</div>
                                            <div className="userTime">
                                                <TimestampConverter timestamp={comment.creation_date} showDateAsString={true} />
                                            </div>
                                        </div>
                                    </div>

                                    {(currentUser.user_id === comment.user_id) || (currentUser.perm_comment === 1) ? (
                                        <div className="trashIconWrapper">
                                            <button
                                                className="appButtonTrashIcon"
                                                onClick={() => handleDeleteButtonClick(comment)}
                                            >
                                                {isSelectedCommentToDelete === comment ? (
                                                    <div>{isDeleteButtonText}</div>
                                                ) : (
                                                    <FontAwesomeIcon icon={faTrash} />
                                                )}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="trashIconWrapper"></div>
                                    )}
                                </div>
                                <div className="userCommentInfo">
                                    <div className="userCommentMessage">{comment.message}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="messageWrapper">
                            <div className="infoMessage">Keine Kommentare vorhanden</div>
                        </div>
                    )}
                </div>

                {currentUser.message === "authorisiert" && currentUser.status === "active" ? (
                    <div className="userCommentTextareaWrapper">
                        <textarea
                            value={isMessage}
                            onChange={(e) => setMessage(e.target.value)}
                            className="userCommentTextarea"
                            name="userTextarea"
                            disabled={remainingTime > 0}
                            placeholder={
                                remainingTime > 0
                                    ? 'Kommentare können nur alle 5 Minuten gesendet werden'
                                    : 'Schreibe einen Kommentar zu diesem Bild'
                            }
                            required
                        />
                        <button
                            className="appButtonSave"
                            onClick={postComment}
                            disabled={remainingTime > 0}
                        >
                            {remainingTime > 0
                                ? `Verbleibende Zeit: ${Math.floor(remainingTime / 60)} Min. ${Math.floor(remainingTime % 60)} Sek.`
                                : 'Kommentar speichern'}
                        </button>
                    </div>
                ) : (
                    <div className="userCommentTextareaWrapper">
                        <textarea
                            className="userCommentTextarea"
                            name="userTextarea"
                            disabled
                            style={currentUser.status === "disabled" ? ({color: '#fb6262'}) : ({color: '#69b9ff'})}
                            value={currentUser.status === "disabled" ?
                                'Dein Benutzeraccount wurde gesperrt! Kontaktiere einen Admin für Hilfe' :
                                (`Um Kommentare senden zu können, benötigst du einen Account. Registriere dich oder logge dich dafür ein.`)
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
}