import React, {forwardRef, useContext, useState} from "react";
import './DropdownButton.css';
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import withClickOutside from "../../withClickOutside";
import {UserContext} from "../../App";

const DropdownButton = forwardRef(({ open, setOpen, handleDivClick }, ref) => {
    const navigate = useNavigate();
    const [isUser, setIsUser] = useLocalStorage('isUser', '');
    const [isToken, setToken] = useLocalStorage('isToken', '');
    const [isHovered, setIsHovered] = useState(false);
    const user = useContext(UserContext);

    const checkIfUserHasPermission = (permission) =>
        user?.group_id === 1 ?
            true :
            (user?.[`perm_${permission}`] === 1 && user.status === "active") || false;


    // Check whether at least one permission is available to display the dropdown admin title
    const relevantProperties = ['perm_object', 'perm_category', 'perm_user', 'perm_group', 'perm_comment'];
    const hasPermissionWithValueOne = Object.values(user)
        .filter((value, index) => relevantProperties.includes(Object.keys(user)[index]))
        .some(value => value === 1);


    const signOut = useSignOut();
    const signOutFunction = () => signOut();


    const handleLogout = () => {
        if (isUser !== "" && isToken !== "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: isUser,
                    loginToken: isToken
                })
            };

            // fetch('http://localhost:80/react/api/logout.php', requestOptions)
            fetch('https://api.seelenwesen.de/logout.php', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    signOutFunction();
                    if (data.message === "Erfolgreich abgemeldet") {
                        setTimeout(function () {
                            setIsUser('');
                            setToken('');
                            navigate("/home");
                            window.location.reload();
                        }, 0);
                    }
                })
                .catch((err) => {});
        }
    }

    const handleMouseOver = () => {
        setOpen(true);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setOpen(false)
    };

    return (
        <div className="DropdownWrapper" ref={ref} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>

            <button className="DropdownButton" onClick={() => setOpen(!open)}>
                Hauptmenü
            </button>

            {open && (
                <div className="DropdownMenu" onClick={handleDivClick} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>

                    {(hasPermissionWithValueOne && user.status === "active") && (
                        <div className="dropdownTitle">Administration</div>
                    )}

                    {checkIfUserHasPermission('object') && (
                        <Link className="dropdownEntry" to={'/manage/images'}>
                            {'Bilder verwalten'}
                        </Link>
                    )}
                    {checkIfUserHasPermission('category') && (
                        <Link className="dropdownEntry" to={'/manage/categories'}>
                            {'Kategorien verwalten'}
                        </Link>
                    )}
                    {checkIfUserHasPermission('user') && (
                        <Link className="dropdownEntry" to={'/manage/users'}>
                            {'Benutzer verwalten'}
                        </Link>
                    )}
                    {checkIfUserHasPermission('group') && (
                        <Link className="dropdownEntry" to={'/manage/groups'}>
                            {'Gruppen verwalten'}
                        </Link>
                    )}
                    {checkIfUserHasPermission('comment') && (
                        <Link className="dropdownEntry" to={'/manage/comments'}>
                            {'Kommentare verwalten'}
                        </Link>
                    )}

                    <div className="dropdownTitle">Einstellungen</div>
                    <Link className="dropdownEntry" to={'/edit/profile'}>
                        {'Mein Profil'}
                    </Link>
                    <div className="DropdownOption-logout" onClick={handleLogout}>Ausloggen</div>
                </div>
            )}

        </div>
    );
});

export default withClickOutside(DropdownButton);
