import React from 'react';
import './FooterSites.css';
import EmailLink from "../../Element/EmailLink/EmailLink";

const PrivacyPolicy = () => {

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Datenschutzerklärung
                        </h1>

                        <div className="formContainer">
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                                <div>

                                    <div className="innerTextWrapper">
                                        <p style={{marginTop: '0', marginBottom: '2rem'}}>
                                            Vielen Dank für Ihren Besuch auf unserer Website. Der Schutz Ihrer Privatsphäre
                                            ist uns
                                            wichtig.
                                            Nachstehend informieren wir Sie über die Verarbeitung Ihrer personenbezogenen
                                            Daten und Ihre
                                            Rechte
                                            im Zusammenhang mit der Nutzung unserer Website.
                                        </p>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Verantwortlich für die Datenverarbeitung auf dieser Website gemäß § 5
                                            TMG:</h2>
                                        <div className="innerTextWrapper">
                                            <div>Marcel Widl</div>
                                            <div>E-Mail: <EmailLink mailTo={'support@seelenwesen.de'}/></div>
                                            <div className="innerTextInfo">(Bitte kontaktieren Sie uns per E-Mail, um
                                                die vollständige Adresse zu erhalten.)
                                            </div>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Arten der verarbeiteten Daten und Zweck der Verarbeitung:</h2>
                                        <div className="innerTextWrapper">
                                            <p>Wir verarbeiten personenbezogene Daten nur, wenn dies zur Bereitstellung
                                                und Optimierung unserer Dienste erforderlich ist. Die Verarbeitung
                                                erfolgt auf Grundlage Ihrer Einwilligung oder wenn es zur Erfüllung
                                                eines Vertrags mit Ihnen oder zur Durchführung vorvertraglicher
                                                Maßnahmen erforderlich ist.</p>
                                            <p>Die von uns verarbeiteten Daten umfassen:</p>
                                            <ul>
                                                <li>Informationen, die Sie uns freiwillig zur Verfügung stellen, wie z.
                                                    B. Ihren Namen, Ihre E-Mail-Adresse und andere Kontaktdaten, wenn
                                                    Sie uns über das Kontaktformular kontaktieren oder sich für unseren
                                                    Newsletter anmelden.
                                                </li>
                                                <li>Informationen, die automatisch durch die Nutzung der Website erhoben
                                                    werden, wie z. B. Ihre IP-Adresse, Browsertyp, Betriebssystem, Datum
                                                    und Uhrzeit des Zugriffs, um die Sicherheit und Stabilität der
                                                    Website zu gewährleisten und um die Nutzung der Website zu
                                                    analysieren und zu verbessern.
                                                </li>
                                                <li>Cookies: Wir verwenden Cookies, um die Authentifizierung zu
                                                    ermöglichen und die Benutzererfahrung zu verbessern. Cookies sind
                                                    kleine Textdateien, die auf Ihrem Gerät gespeichert werden. Sie
                                                    können die Verwendung von Cookies in den Einstellungen Ihres
                                                    Browsers kontrollieren und gegebenenfalls ablehnen.
                                                </li>
                                                <li>LocalStorage: Wir nutzen den LocalStorage Ihres Geräts, um nicht
                                                    personenbezogene Daten wie Einstellungen zu speichern.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Verwendung von Font Awesome Icons:</h2>
                                        <div className="innerTextWrapper">
                                            <p>Wir verwenden Icons von Font Awesome auf unserer Website. Font Awesome
                                                erhebt möglicherweise anonyme Statistiken über die Verwendung der Icons
                                                zu Analysezwecken. Weitere Informationen finden Sie in der
                                                Datenschutzerklärung von Font Awesome: <a
                                                    href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Galerieinhalt:</h2>
                                        <div className="innerTextWrapper">
                                            <p>Alle Bilder in dieser Galerie wurden von Silvia Riedmaier selbst
                                                aufgenommen. Jegliche Verwendung oder Weitergabe dieser Bilder bedarf
                                                ihrer Zustimmung.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Ihre Rechte:</h2>
                                        <div className="innerTextWrapper">
                                            <p>Sie haben das Recht, Auskunft über die von uns über Sie gespeicherten
                                                personenbezogenen Daten zu erhalten, sowie das Recht auf Berichtigung,
                                                Löschung oder Einschränkung der Verarbeitung dieser Daten. Darüber
                                                hinaus haben Sie das Recht, der Verarbeitung Ihrer Daten zu
                                                widersprechen sowie das Recht auf Datenübertragbarkeit.</p>
                                            <p>Wenn Sie Fragen oder Bedenken zur Verarbeitung Ihrer personenbezogenen
                                                Daten haben oder Ihre Rechte ausüben möchten, können Sie sich jederzeit
                                                unter den oben angegebenen Kontaktdaten an uns wenden.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Aktualisierungen der Datenschutzerklärung:</h2>
                                        <div className="innerTextWrapper">
                                            <p>Diese Datenschutzerklärung kann aktualisiert werden, um Änderungen in
                                                unserer Datenschutzpraxis widerzuspiegeln. Bitte überprüfen Sie diese
                                                Seite regelmäßig auf Aktualisierungen.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <div className="innerTextWrapper">
                                            <p>Letzte Aktualisierung: 16.03.2024</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default PrivacyPolicy;
