import React, { useState } from "react";
import './DropdownButtonOnline.css';
import formatName from "../Utilities/NameFormatter";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DropdownButtonOnline = ({ isOnline }) => {
    const [isOpen, setOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const usersPerPage = 5;

    const handleNextPage = (event) => {
        setPageIndex(prevPageIndex => prevPageIndex + 1);
        event.stopPropagation()
    };

    const handlePreviousPage = (event) => {
        setPageIndex(prevPageIndex => prevPageIndex - 1);
        event.stopPropagation()
    };

    return (
        <div
            className="DropdownWrapperOnline"
            onClick={() => setOpen(!isOpen)}
        >
            <button
                className="DropdownButtonOnline"
                onMouseLeave={
                    () => {setOpen(false); setPageIndex(0)}
                }
            >
                {`Benutzer online: ${isOnline?.onlineCount}`}
            </button>

            {isOpen && (
                <div className="DropdownMenuOnline">
                    {isOnline.users.slice(pageIndex * usersPerPage, (pageIndex + 1) * usersPerPage).map((user, index) => {
                        return (
                            <div key={`${user.username}-${index}`} className="dropdownEntryOnline">
                                <div className="dropdownEntryStatus"></div>
                                {formatName(user.username)}
                            </div>
                        );
                    })}

                    {isOnline?.onlineCount >= usersPerPage && (
                        <div className="paginationButtons">
                            <button
                                className="appButtonCancel"
                                disabled={pageIndex === 0}
                                onClick={handlePreviousPage}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>

                            <button
                                className="appButtonCancel"
                                disabled={(pageIndex + 1) * usersPerPage >= isOnline.users.length}
                                onClick={handleNextPage}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default DropdownButtonOnline;