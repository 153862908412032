import React, { useContext, useRef, useState, useEffect } from "react";
import './Gallery.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ImagePreview from "../../Element/ImagePreview/ImagePreview";
import ReactParallaxTilt from "react-parallax-tilt";
import GetValueFromForeignKey from "../../Element/Utilities/GetValueFromForeignKey";
import { UserContext } from "../../App";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";

export default function Gallery({ images, categories, reloadAfterChanges, isResponsive }) {
    const user = useContext(UserContext);
    const [isToken, setToken] = useLocalStorage('isToken', '');
    const [isParallax, setParallax] = useLocalStorage('isParallax', 'true');
    const chunkSize = 1;
    const [showPreview, setShowPreview] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const deleteButtonTimerRef = useRef(null);
    const [isDeleteButtonText, setDeleteButtonText] = useState("");
    const [isSelectedImageToDelete, setSelectedImageToDelete] = useState(null);
    const [showMoreButton, setShowMoreButton] = useState(true);
    const [loadedImages, setLoadedImages] = useState(20);
    const [isLikedImages, setLikedImages] = useState([]);
    const [isChanged, setChanged] = useState(false)

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${user.username}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    useEffect(() => {
        const fetchLikedImages = async () => {
            try {
                const response = await axios.get("/registeredLikes.php", {
                    headers: {
                        Authorization: `${token}`,
                        RequestUser: `${user.username}`,
                        LToken: `${isToken}`,
                    },
                });
                setLikedImages(response.data);
                setChanged(false)
            } catch (error) {
                console.error("Fehler beim Abrufen der Bilder likes: ", error);
            }
        };

        if (user && isToken) {
            fetchLikedImages();
        }

    }, [user, isToken, isChanged]);

    const checkIfUserHasPermission = (permission) =>
        user?.group_id === 1 ?
            true :
            (user?.[`perm_${permission}`] === 1 && user.status === "active") || false;

    const openPreview = (columnIndex, indexInColumn) => {
        const globalIndex = columnIndex * chunkSize + indexInColumn;
        setSelectedImageIndex(globalIndex);
        setShowPreview(true);
    };

    const closePreview = () => {
        setSelectedImageIndex(null);
        setShowPreview(false);
    };

    async function deleteObject(objectData) {
        if (checkIfUserHasPermission('object')) {
            try {
                const response = await axios.delete("/mObjects.php", {
                    data: { id: objectData.id },
                    headers: config.headers,
                });

                if (response.data.status === 1) {
                    reloadAfterChanges()
                }

            } catch (error) {
                console.error("Fehler beim Löschen des Bildes: ", error);
            }
        }
    }

    const handleDeleteButtonClick = (image) => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        if (isSelectedImageToDelete === image) {
            deleteObject(image);
        } else {
            setDeleteButtonText("2x");
            setSelectedImageToDelete(image);
        }

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("");
            setSelectedImageToDelete(null); // Reset image to delete
        }, 2000);
    };

    const toggleLike = async (imageId) => {
        try {
            const existingLike = isLikedImages.find(item => item.object_id.toString() === imageId && item.hasLiked === 1);

            if (existingLike) {
                await axios.delete(`/registeredLikes.php`, {
                    headers: config.headers,
                    data: { object_id: imageId } // Send imageId in the request body
                });
                setLikedImages(isLikedImages.filter(item => item.object_id !== imageId));
                setChanged(true)
            } else {
                await axios.post("/registeredLikes.php", { object_id: imageId }, {
                    headers: config.headers,
                });
                setLikedImages([...isLikedImages, { object_id: imageId, hasLiked: 1 }]);
                setChanged(true)
            }
        } catch (error) {
            console.error("Fehler beim Ändern des Likes: ", error);
        }
    };

    const loadMoreImages = () => {
        const newLoadedImages = loadedImages + 20;
        if (newLoadedImages >= images.length) {
            setShowMoreButton(false);
        }
        setLoadedImages(newLoadedImages);
    };

    const visibleImages = images.slice(0, loadedImages);

    const chunkedImages = Array.isArray(visibleImages)
        ? visibleImages.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize);
            if (!resultArray[chunkIndex]) { resultArray[chunkIndex] = []; }
            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []) : [];

    const reloadData = () => {
        setChanged(true)
    }

    return (
        <div className="row">
            {chunkedImages.map((column, columnIndex) => (
                <div key={columnIndex} className="column">
                    {column.map((image, indexInColumn) => (
                        <div key={image.id} className="imageScaleWrapper">
                            <div className="parallaxWrapper">
                                {(isResponsive === true) || (isParallax === "false") ? (
                                    <div className="imageContainer">
                                        <Link
                                            key={`link ${indexInColumn}`}
                                            value={image.id}
                                            to={`/category/${image.category_id}`}
                                        >
                                            <span className="infoItemCategory">
                                                {GetValueFromForeignKey(image.category_id, categories, 'name')}
                                            </span>
                                        </Link>

                                        {checkIfUserHasPermission('object') && (
                                            <div className="infoItemDelete">
                                                <button
                                                    className="infoItemDeleteIcon"
                                                    onClick={() => handleDeleteButtonClick(image)}
                                                >
                                                    {isSelectedImageToDelete === image ? (
                                                        <div>{isDeleteButtonText}</div>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    )}
                                                </button>
                                            </div>
                                        )}

                                        <img src={image.thumbnail_path} width="100%" alt={`${indexInColumn + 1}`}
                                             onClick={() => openPreview(columnIndex, indexInColumn)} />

                                        <div className="bottomInfo"
                                        >
                                            <div className="infoItem">{image.name || "——"}</div>
                                            <div className="infoIconWrapper">
                                                {Array.isArray(isLikedImages) && ( // Überprüfen, ob isLikedImages ein Array ist
                                                    user && isToken && (
                                                        <div
                                                            className={`infoItem ${isLikedImages.some(item => item.object_id === parseInt(image.id) && item.hasLiked === 1) ? 'is-liked' : 'is-not-liked'}`}
                                                            onClick={() => toggleLike(image.id)}
                                                        >
                                                            <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                                {isLikedImages.find(item => item.object_id === parseInt(image.id))?.count || ''}
                                                            </div>
                                                            <FontAwesomeIcon
                                                                className="infoIconLike"
                                                                icon={faThumbsUp}
                                                            />
                                                        </div>
                                                    )
                                                )}

                                                <div
                                                    className={`infoItem ${image.comments_count < 1 ? 'is-noComments' : ''}`}
                                                >
                                                    <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                        {image.comments_count || ''}
                                                    </div>
                                                    <FontAwesomeIcon className="infoIconComment" icon={faComments}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <ReactParallaxTilt>
                                        <div className="imageContainer">
                                            <Link
                                                key={`link ${indexInColumn}`}
                                                value={image.id}
                                                to={`/category/${image.category_id}`}
                                            >
                                                <span className="infoItemCategory">
                                                    {GetValueFromForeignKey(image.category_id, categories, 'name')}
                                                </span>
                                            </Link>

                                            {checkIfUserHasPermission('object') && (
                                                <div className="infoItemDelete">
                                                    <button
                                                        className="infoItemDeleteIcon"
                                                        onClick={() => handleDeleteButtonClick(image)}
                                                    >
                                                        {isSelectedImageToDelete === image ? (
                                                            <div>{isDeleteButtonText}</div>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        )}
                                                    </button>
                                                </div>
                                            )}

                                            <img src={image.thumbnail_path} width="100%" alt={`${indexInColumn + 1}`}
                                                 onClick={() => openPreview(columnIndex, indexInColumn)} />
                                            <div className="bottomInfo"
                                            >
                                                <div className="infoItem">{image.name || "——"}</div>
                                                <div className="infoIconWrapper">
                                                    {Array.isArray(isLikedImages) && ( // Überprüfen, ob isLikedImages ein Array ist
                                                        user && isToken && (
                                                            <div
                                                                className={`infoItem ${isLikedImages.some(item => item.object_id === parseInt(image.id) && item.hasLiked === 1) ? 'is-liked' : 'is-not-liked'}`}
                                                                onClick={() => toggleLike(image.id)}
                                                            >
                                                                <div style={{
                                                                    fontSize: '0.65rem',
                                                                    paddingTop: '0.15rem'
                                                                }}>
                                                                    {isLikedImages.find(item => item.object_id === parseInt(image.id))?.count || ''}
                                                                </div>
                                                                <FontAwesomeIcon
                                                                    className="infoIconLike"
                                                                    icon={faThumbsUp}
                                                                />
                                                            </div>
                                                        )
                                                    )}

                                                    <div
                                                        className={`infoItem ${image.comments_count < 1 ? 'is-noComments' : ''}`}
                                                    >
                                                        <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                            {image.comments_count || ''}
                                                        </div>
                                                        <FontAwesomeIcon className="infoIconComment" icon={faComments}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ReactParallaxTilt>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            {showMoreButton && (loadedImages < images.length) && (
                <div className="loadMoreButtonWrapper">
                    <button className="loadMoreButton" onClick={loadMoreImages}>
                        Mehr anzeigen
                    </button>
                </div>
            )}
            {showPreview && (
                <ImagePreview
                    images={images}
                    categories={categories}
                    selectedIndex={selectedImageIndex}
                    onClose={closePreview}
                    currentUser={user || {}}
                    reloadAfterChanges={reloadAfterChanges}
                />
            )}
        </div>
    );
}
