import React, {useContext} from 'react';
import '../FooterSites/FooterSites.css';
import {UserContext} from "../../App";
import EmailLink from "../../Element/EmailLink/EmailLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
    const user = useContext(UserContext);

    return (
        <div className="contentWrapper">
            <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                <div className="loginFormContent">
                    <h1 className="loginTitle">
                        Über Silvia Riedmaier
                    </h1>

                    <div className="formContainer">
                        <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                            <div>

                                <div className="innerEditTopWrapper">
                                    <div className="innerEditTopLeft">
                                        <img className="innerEditTopImg" src={'/images/aboutme/Silvia1.png'} alt={'Silvia1.png'}/>
                                    </div>

                                    <div className="innerEditTopRight">
                                        <div className="entryContainer">
                                            <h3>Einleitung:</h3>
                                            <div className="innerTextWrapper">
                                                <p>
                                                    Willkommen auf Seelenwesen.de, wo die Schönheit der Natur durch die
                                                    Linse
                                                    einer Fotografin mit einer einzigartigen Perspektive eingefangen
                                                    wird.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="entryContainer">
                                            <h3>Hintergrund:</h3>
                                            <div className="innerTextWrapper">
                                                <p>
                                                    Silvia hat eine bewegte Vergangenheit hinter sich, die von
                                                    Herausforderungen
                                                    und Schwierigkeiten geprägt war. Doch inmitten dieser Turbulenzen
                                                    entdeckte
                                                    sie eine
                                                    unerwartete Quelle der Hoffnung und Inspiration: die Kunst der
                                                    Fotografie.
                                                    Mit einer Kamera
                                                    in der Hand und einem offenen Herzen begann sie die Welt um sich
                                                    herum
                                                    auf
                                                    eine neue Weise wahrzunehmen.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h3>Die Kraft der Natur:</h3>
                                    <div className="innerTextWrapper">
                                        <p>
                                            Für Silvia ist die Natur nicht nur ein Motiv, sondern eine Quelle der Kraft
                                            und Heilung. Durch die Linse ihrer Kamera betrachtet sie die Welt
                                            aus verschiedenen Blickwinkeln, entdeckt Schönheit in den kleinsten Details
                                            und findet Trost in der endlosen Weite der Landschaft.
                                        </p>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h3>Was dich erwartet:</h3>
                                    <div className="innerTextWrapper">
                                        <p>
                                            Auf Seelenwesen.de teilt Silvia ihre Fotografien und die Geschichten
                                            dahinter.
                                            Jedes Bild erzählt eine eigene Geschichte von Überwindung, Hoffnung
                                            und Resilienz. Tauche ein in die Welt der Naturfotografie und lass dich von
                                            der
                                            Schönheit unserer Welt verzaubern.
                                        </p>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h3>Kontaktiere uns:</h3>
                                    <div className="innerTextWrapper">
                                        <p>
                                            Hast du Fragen, Anregungen oder möchtest du mehr über Silvia und ihre
                                            Arbeit erfahren? Zögere nicht, uns zu kontaktieren! Du kannst uns unter
                                            <EmailLink mailTo={'contact@seelenwesen.de'}/>erreichen.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

};

export default AboutMe;
